import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Article from '../components/article'
import Cta from '../components/cta'
import { StaticImage } from 'gatsby-plugin-image'

const HomeEmergencyCover = () => {
  return (
    <>
      <Seo
        pageTitle={'Home Emergency Cover'}
        description={
          'This reassuring 24-hour home emergency insurance cover can offer valuable peace of mind against things like heating, plumbing and electrical problems.'
        }
      />
      <Layout>
        <main>
          <Article>
            <div className='card shadow border-primary mb-3 p-2'>
              <StaticImage
                className='card-img-top'
                src={'../images/emergency.png'}
                alt={'Combined Home Insurance'}
                placeholder='blurred'
                Layout={'fullWidth'}
                formats={['auto', 'webp', 'avif']}
                quality={100}
              ></StaticImage>
            </div>

            <h1 className='display-4'>Home Emergency Cover</h1>
            <p>
              This reassuring 24-hour home emergency insurance cover can offer
              valuable peace of mind against things like heating, plumbing and
              electrical problems.
            </p>
            <p>
              We can include Home Emergency cover onto any policy that has
              buildings insurance cover or even as a stand-alone insurance
              policy.
            </p>

            <p>
              Our Home Emergency policy will provide cover in an emergency for:
            </p>
            <ul className='list-group list-group'>
              <li className='list-group-item'>
                Up to a maximum of £1,000 or 3 call outs per year.
              </li>
              <li className='list-group-item'>Plumbing and drainage issues</li>
              <li className='list-group-item'>
                Failure of your electricity supply
              </li>
              <li className='list-group-item'>
                Damage to your roofing causing water to enter the property
              </li>
              <li className='list-group-item'>
                Making your home secure following unexpected failure or damage
                to external locks
              </li>
              <li className='list-group-item'>
                Assisting you to gain entry to your home following failure of a
                lock or snapping of a key
              </li>
              <li className='list-group-item'>
                Assisting you to restore a means of heating and preparing food
                following complete failure of the permanently installed cooking
                system (up to £250)
              </li>
              <li className='list-group-item'>
                Removal or extermination of wasps, hornets, rats and mice
                infestation in your home
              </li>
              <li className='list-group-item'>Failure of your gas supply</li>
              <li className='list-group-item'>Restoring your water supply</li>
              <li className='list-group-item'>
                Failure of your primary heating system (terms and conditions
                apply)
              </li>
            </ul>
            <Cta />
          </Article>
        </main>
      </Layout>
    </>
  )
}

export default HomeEmergencyCover
